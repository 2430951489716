import React from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Form, Input, Segment, Header, Button, Icon } from 'semantic-ui-react';
import NodeDataView from './NodeDataView';
import modelEditor from 'http/modelEditor';
import reportEditor from 'http/reportEditor';
import { truncString } from 'shared/truncString';
import useRestResource from 'shared/hooks/useRestResource';

const VersionPicker = ({ reportTemplateID, value, onChange }) => {
    const { loading, data, error } = useRestResource({
        fetcher: () => {
            if (!reportTemplateID) {
                return Promise.resolve([]);
            }
            return reportEditor.getExportedTemplateVersions(reportTemplateID);
        },
        args: [reportTemplateID],
    });

    const versions = data || [];

    return (
        <Dropdown
            fluid
            selection
            clearable
            loading={loading}
            error={error}
            disabled={versions.length === 0}
            onChange={(_, { value }) => {
                const selectedTemplate = versions.find(v => v.version === value);
                onChange(value, selectedTemplate.outputAsIXBRL);
            }}
            value={value}
            options={versions.map(({ version, description }) => ({
                value: version,
                text: `Version ${version} - ${truncString(description)}`,
            }))}
        />
    );
};

class NodeDataYearReportData extends React.Component {
    state = {
        loading: true,
        selectedReportTemplate: '',
        selectedTemplateVersion: -1,
        renderSelectedTemplateAsIXBRL: false,
        taxBookletDocument: null,
        templates: null,

        validationResult: null,
        isValidating: false,
    };

    componentDidMount = async () => {
        const { selectedReportTemplate, selectedTemplateVersion, renderSelectedTemplateAsIXBRL, secondaryDocuments } = this.props.node.data;

        const templates = await reportEditor.getTemplates();
        const [taxBookletDocument] = secondaryDocuments || [];

        this.setState({
            taxBookletDocument,
            selectedReportTemplate,
            selectedTemplateVersion,
            renderSelectedTemplateAsIXBRL,
            templates,
            loading: false,
        });
    };

    getView () {
        let view = null;
        if (this.data && this.data.getView) {
            view = this.data.getView();
        }
        return view;
    }

    getData() {
        const { selectedReportTemplate, selectedTemplateVersion, renderSelectedTemplateAsIXBRL, taxBookletDocument } = this.state;

        const taxBookletValid = (
            taxBookletDocument &&
            taxBookletDocument.reportTemplateID &&
            taxBookletDocument.templateVersion >= 1 &&
            taxBookletDocument.title
        );

        return {
            selectedReportTemplate,
            selectedTemplateVersion,
            renderSelectedTemplateAsIXBRL,
            secondaryDocuments: taxBookletValid ? [taxBookletDocument] : [],
        };
    }

    updateTaxBooklet = (updator) => {
        const { taxBookletDocument } = this.state;

        this.setState({
            taxBookletDocument: { ...taxBookletDocument, ...updator },
        });
    };

    doInputValidation = async () => {
        const { selectedReportTemplate, selectedTemplateVersion, secondaryDocuments } = this.getData();

        const templatesToValidateAgainst = [];
        
        if (selectedReportTemplate && selectedTemplateVersion) {
            templatesToValidateAgainst.push({
                templateID: selectedReportTemplate,
                templateVersion: selectedTemplateVersion,
            });
        }

        for (const { reportTemplateID, templateVersion } of secondaryDocuments) {
            if (reportTemplateID && templateVersion) {
                templatesToValidateAgainst.push({
                    templateID: reportTemplateID,
                    templateVersion: templateVersion,
                });
            }
        }

        const allTemplates = await Promise.all(templatesToValidateAgainst.map(({ templateID, templateVersion  }) => {
            return reportEditor.getTemplate(templateID, templateVersion);
        }));

        const fullModel = await modelEditor.getModel(this.props.modelId);

        const collectDependenciesFrom = (nodeID, seen = new Set()) => {
            const edges = fullModel.edges.filter(edge => {
                return edge.from === nodeID;
            });
            
            for (const edge of edges) {
                seen.add(edge.to);
                collectDependenciesFrom(edge.to, seen);
            }

            return seen;
        };

        const nodes = [...collectDependenciesFrom(this.props.node.id)].map(nodeID => {
            return this.props.nodes.find(node => node.id === nodeID);
        });

        const tagsSeenAcrossAllTemplates = {};

        const allErrors = {
            dataTypeMismatchesBetweenReportTemplates: [],
            dataTypeMismatchesBetweenModelAndReportTemplates: [],
            inputDefinitionsWithMissingNodes: [],
        };

        for (const template of allTemplates) {
            for (const inputDefinition of template.data.inputDefinitions) {
                const { tag, dataType } = inputDefinition;

                if (tag in tagsSeenAcrossAllTemplates) {
                    const previouslySeenDefinition = tagsSeenAcrossAllTemplates[tag];
                    if (previouslySeenDefinition.dataType !== dataType) {
                        allErrors.dataTypeMismatchesBetweenReportTemplates.push(`${tag}: Datatype mismatch: ${dataType} vs. ${previouslySeenDefinition.dataType}`);
                    }
                } else {
                    tagsSeenAcrossAllTemplates[tag] = inputDefinition;
                }
            }
        }


        for (const inputDefinition of Object.values(tagsSeenAcrossAllTemplates)) {
            if (!inputDefinition.tag) continue;

            const node = nodes.find(node => node.tag === inputDefinition.tag);
            if (!node) {
                allErrors.inputDefinitionsWithMissingNodes.push(inputDefinition.tag);
                continue;
            }

            let nodeDataType = node.dataType;
            if (nodeDataType === 'EnumString') {
                nodeDataType = 'String';
            }

            if (nodeDataType !== inputDefinition.dataType) {
                allErrors.dataTypeMismatchesBetweenModelAndReportTemplates.push(`${node.tag}: ${nodeDataType} i model, ${inputDefinition.dataType} i rapport`);
                continue;
            }
        }

        return allErrors;
    };

    renderDropdownLoadingPlaceholder = () => {
        return (
            <Dropdown
                key={Math.random()}
                fluid
                selection
                loading
                disabled
            />
        );
    };

    renderTemplatePicker = ({ selectedReportTemplate, onChange }) => {
        const { templates, loading } = this.state;

        return (
            <Dropdown
                fluid
                selection
                search
                clearable
                loading={loading}
                onChange={(_, { value }) => onChange(value)}
                value={selectedReportTemplate}
                options={templates?.map(({ name, id }) => ({
                    value: id,
                    text: name,
                }))}
            />
        );
    };

    renderValidationResult = () => {
        const { validationResult } = this.state;
        if (!validationResult) {
            return null;
        }

        const renderErrorGroup = (title, messages) => {
            if (messages.length === 0) return null;

            return (
                <div>
                    <strong>{title}</strong>
                    {messages.map(message => <div>{message}</div>)}
                </div>
            );
        };

        const {
            dataTypeMismatchesBetweenReportTemplates,
            dataTypeMismatchesBetweenModelAndReportTemplates,
            inputDefinitionsWithMissingNodes,
        } = validationResult;

        const allErrors = [
            renderErrorGroup('Afvigelser i datatype mellem rapportskabelon', dataTypeMismatchesBetweenReportTemplates),
            renderErrorGroup('Afvigelser i datatype mellem rapportskabelon og model', dataTypeMismatchesBetweenModelAndReportTemplates),
            renderErrorGroup('Input tags til rapport som ikke blev fundet i modellen', inputDefinitionsWithMissingNodes),
        ].filter(x => x);

        return (
            <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em' }}>
                {allErrors.length === 0 && (
                    <span>
                        <Icon name='check circle' color='green' />
                        Ingen fejl
                    </span>
                )}
                {allErrors}
            </div>
        );
    };

    render () {
        const { modelId, node } = this.props;
        const { selectedReportTemplate, selectedTemplateVersion, taxBookletDocument } = this.state;
        
        return (
            <div>
                <Segment>
                    <h3>Main report</h3>
                    <Form>
                        <Form.Field>
                            <label>Select a template</label>
                            {this.renderTemplatePicker({
                                selectedReportTemplate,
                                onChange: value => this.setState({ selectedReportTemplate: value, selectedTemplateVersion: -1 }),
                            })}
                        </Form.Field>
                        <Form.Field>
                            <label>Select a version</label>
                            <VersionPicker
                                reportTemplateID={selectedReportTemplate}
                                value={selectedTemplateVersion}
                                onChange={(selectedTemplateVersion, renderSelectedTemplateAsIXBRL) => {
                                    this.setState({
                                        selectedTemplateVersion,
                                        renderSelectedTemplateAsIXBRL,
                                    });
                                }}
                            />
                        </Form.Field>
                    </Form>
                </Segment>

                <Segment>
                    <h3>Tax booklet</h3>
                    <Form>
                        <Form.Field>
                            <label>Select a template</label>
                            {this.renderTemplatePicker({
                                selectedReportTemplate: taxBookletDocument?.reportTemplateID,
                                onChange: value => this.updateTaxBooklet({ reportTemplateID: value, templateVersion: -1 }),
                            })}
                        </Form.Field>
                        <Form.Field>
                            <label>Select a version</label>
                            <VersionPicker
                                reportTemplateID={taxBookletDocument?.reportTemplateID}
                                value={taxBookletDocument?.templateVersion}
                                onChange={templateVersion => this.updateTaxBooklet({ templateVersion })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Input a title</label>
                            <Input
                                fluid
                                defaultValue={taxBookletDocument?.title}
                                onChange={(_, { value }) => this.updateTaxBooklet({ title: value })}
                            />
                        </Form.Field>
                    </Form>
                </Segment>

                <Segment>
                    <Header>Inputvalidering</Header>
                    <Button
                        primary
                        icon='cog'
                        content='Foretag inputvalidering'
                        loading={this.state.isValidating}
                        disabled={this.state.isValidating}
                        onClick={() => {
                            this.setState({ isValidating: true, validationResult: null });
                            this.doInputValidation()
                                .then(validationResult => this.setState({ validationResult }))
                                .catch(e => toast.error(e.message))
                                .finally(() => this.setState({ isValidating: false }));
                        }}
                    />

                    {this.renderValidationResult()}
                </Segment>
                
                <NodeDataView
                    ref={data => this.data = data}
                    node={node}
                    modelId={modelId}
                />
            </div>
        );
    }

}

export default NodeDataYearReportData;