import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/taxonomy-parser/api`);

class TaxonomyParserClient {
    /**
     * Official taxonomy definition uses the following language codes:
     *   - Danish: da
     *   - English: en
     * 
     * The supported languages property of metadata contains different codes so we need to map them to the ones the official taxonomy definition uses.
     * If the value 'default' is provided, the offical default language of the taxonomy will be used.
     */
    taxonomyLanguages = {
        default: 'default',
        dk: 'da',
        gb: 'en'
    };

    getTaxonomyVersions = () => {
        return client.getResource(`/taxonomies`);
    }
    
    listTags = (taxonomyID, language = 'default') => {
        return client.getResource(`/taxonomies/${taxonomyID}/tags?language=${language}`);
    };

    getTaxonomyMetadata = taxonomyID => {
        return client.getResource(`/taxonomies/${taxonomyID}/metadata`);
    };

    getMemberDimensionsByTag = (taxonomyID, tagID) => {
        return client.getResource(`/taxonomies/${taxonomyID}/tags/${tagID}/member-dimensions`);
    };

    getAllChildrenByTag = (taxonomyID, tagID) => {
        return client.getResource(`/taxonomies/${taxonomyID}/tags/${tagID}/all-children`);
    };

    getTaxonomyPresentationOrder = (taxonomyID) => {
        return client.getResource(`/taxonomies/${taxonomyID}/presentation-order`);
    };
}

const taxonomyParser = new TaxonomyParserClient();

export default taxonomyParser;
